import { template as template_9374d61e400947c8a5ce5249c3bcc7c8 } from "@ember/template-compiler";
const WelcomeHeader = template_9374d61e400947c8a5ce5249c3bcc7c8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
