import { template as template_0dfc4c5b1c5a48a1a3627d53661376e5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_0dfc4c5b1c5a48a1a3627d53661376e5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
