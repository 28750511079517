import { template as template_521afa4d3fd44e508fa50f7942160f8e } from "@ember/template-compiler";
const FKLabel = template_521afa4d3fd44e508fa50f7942160f8e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
