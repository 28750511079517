import { template as template_807085c048564745ba9915dadc51f77d } from "@ember/template-compiler";
const FKControlMenuContainer = template_807085c048564745ba9915dadc51f77d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
